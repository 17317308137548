import React from 'react'

function CloudProj() {
  return (
    <div>
      <div>
        <img
          src="/images/cloud-banner.svg"
          alt=""
          style={{ width: '30rem', height: '100%' }}
        />
      </div>
      <p className="project-description">
        Friday project (8-10hours to complete). A full stack React project where
        you can save your favourite Dev Academy memories. You can also those
        memories. It sounds fanciful but our main object was to explore adding
        items to a chart and purchasing them. I stepped up as overall project
        lead, overseeing back-end and front-end.
      </p>
    </div>
  )
}

export default CloudProj
