import React from 'react'

function ComingSoonProj() {
  return (
    <div>
      <div>
        <img
          src="/images/next-banner.svg"
          alt=""
          style={{ width: '30rem', height: '100%' }}
        />
      </div>
      <p className="project-description">
        To keep my skills sharpened and to further develop I am delving into
        React Native, Typescript and AWS. That being said I am open to learning
        other technologies and languages as well. I intend to build
        mini-projects to apply what I learned. Its like Bootcamp never ended.
      </p>
    </div>
  )
}

export default ComingSoonProj
