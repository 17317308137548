import React, { useState, useEffect } from 'react'

function Nav() {
  const navbarFixed = {
    display: 'flex',
    position: 'fixed',
    height: '8rem',
    width: '100%',
    padding: '0 18.1rem',
    top: '0',
    transition: 'top 0.6s',
  }

  return (
    <div className="nav-general" style={{ ...navbarFixed }}>
      <ul className="nav-list">
        <a className="nav-box" href="#home">
          <div className="nav-link">Home</div>
        </a>
        <a className="nav-box" href="#about">
          <div className="nav-link">About</div>
        </a>
        <a className="nav-box" href="#eda">
          <div className="nav-link">Dev Academy</div>
        </a>
        <a className="nav-box" href="#projects">
          <div className="nav-link">Projects</div>
        </a>
        <a className="nav-box" href="#contact">
          <div className="nav-link">Contact</div>
        </a>
      </ul>
    </div>
  )
}

export default Nav
