import React from 'react'

function Contact() {
  return (
    <section id="contact" className="section">
      <h3 className="section-heading">Say hello.</h3>
      <div className="contact-content">
        <div className="contact-paragraphs">
          <p>
            Thank you for stopping by 👋. If you want to get in touch then send
            me an email to tiaanj06@gmail.com or flick me a message on{' '}
            <a href="https://www.linkedin.com/in/tiaan-jonker-45283380/">
              Linkedin
            </a>
            . Alternatively give me a call on 021 255 6891. Look forward to hearing
            from you.
          </p>
          <button className="btn">
            <a
              href="mailto:tiaanj06@gmail.com"
              target="_blank"
              rel="noreferrer"
            >
              Send an email
            </a>
          </button>
        </div>
      </div>
    </section>
  )
}

export default Contact
