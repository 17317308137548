import React, { useState, useEffect } from 'react'
import Home from './Home'
import Introduction from './Introduction'
import EDA from './EDA'
import Projects from './Projects'
import Contact from './Contact'
import Footer from './Footer'
import Nav from './Nav'

function OtherDisplay() {
  return (
    <>
      <>
        <Nav />
        <Home />
        <Introduction />
        <EDA />
        <Projects />
        <Contact />
        <Footer />
      </>
    </>
  )
}

export default OtherDisplay
