import React from 'react'

const Home = () => {
  return (
    <section id="home" className="section">
      <div className="home-section-container">
        <div className="logo-container">
          <img src="/images/web-logo.svg" alt="" className="main-logo" />
        </div>
        <div className="main-text-container">
          <h1 className="main-heading-one">Software</h1>
          <h1 className="main-heading-two">Developer</h1>
          <h2 className="main-description">Telling computers what to do.</h2>
        </div>
      </div>
    </section>
  )
}

export default Home
