import React from 'react'

function Introduction() {
  return (
    <section id="about" className="section">
      <h3 className="section-heading">Hello.</h3>
      <div className="wave"></div>
      <div className="intro-content">
        <div className="intro-paragraphs">
          <p>My name is Tiaan Jonker.</p>
          <p>
            Twelve months ago I came to a point in my life where I had to decide
            what next. I explored far and wide and discovered the world of
            software engineering. I was hooked, enrolled in{' '}
            <a
              href="https://devacademy.co.nz/"
              target="_blank"
              className="link"
              rel="noreferrer"
            >
              Dev Academys
            </a>{' '}
            15 weeks intensive Bootcamp and quit my job before even knowing
            whether I got accepted or not.
          </p>
          <p>
            That might seem unwise but I was determined. I knew what I wanted. I
            wanted to become a software developer either via the self-taught or
            Bootcamp route. Spoiler: I got accepted, learned some amazing
            things, made new friends and graduated equipped with the skills to
            enter the industry.
          </p>
          <p>
            Now I can't imagine wanting to do anything else. I get excited about
            code (I never thought I would ever say that) but more importantly, I
            enjoy building human-centred products. I am now ready to enter the
            industry as a software developer and help build something incredible
            while making new relationships in the process.
          </p>
          <p>
            I’m looking for a full time front-end developer position in New
            Zealand so that I can apply everything I have learned and develop
            further while contributing to some amazing projects and products.
          </p>
          <button className="btn">
            <a href="/assets/tiaan-CV.pdf" target="_blank" rel="noreferrer">
              View resume
            </a>
          </button>
        </div>
        <div>
          <img src="/images/profile.jpg" alt="" className="intro-image" />
        </div>
      </div>
    </section>
  )
}

export default Introduction
