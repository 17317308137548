import React from 'react'

function BlogProj() {
  return (
    <div>
      <div>
        <img src="/images/blog-banner.svg" alt="" />
      </div>
      <p className="project-description">
        First website built and deployed. It documents my Dev Academy
        foundations journey (pre-bootcamp course) and its entirely in HTML and
        CSS. While it is relatively basic it demonstrates how far I have come
        compared to me and my team final "Might Bushwalker" project. You can
        view the blog{' '}
        <a
          href="https://tiaan-jonker.github.io/"
          target="_blank"
          rel="noreferrer"
          className="link"
        >
          here
        </a>
        .
      </p>
    </div>
  )
}

export default BlogProj
