import React from 'react'

function BushWalkProj() {
  return (
    <div>
      <div>
        <img
          src="/images/mighty-banner.svg"
          alt=""
          style={{ width: '30rem', height: '100%' }}
        />
      </div>
      <p className="project-description">
        Final Dev Acedemy project: a full-stack React app that finds hiking
        tracks to walk near your location with an in-built progression system.
        To see an ‘advertisement’-style video presenting sections of the app
        <a
          href="https://youtu.be/NvA5cs7Y_vU?t=2184"
          target="_blank "
          className="link"
        >
          click here
        </a>
        . The repo can be viewed{' '}
        <a
          href="https://github.com/tiaan-jonker/Mighty-Bushwalker"
          target="_blank "
          className="link"
        >
          here
        </a>
        .
      </p>
    </div>
  )
}

export default BushWalkProj
