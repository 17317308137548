import React from 'react'

function Footer() {
  return (
    <footer className="footer">
      <div>
        <img src="/images/web-logo.svg" alt="" className="footer-logo" />
      </div>
      <div className="footer-content">
        <div>
          <a
            href="https://github.com/tiaan-jonker"
            target="_blank"
            rel="noreferrer"
          >
            <img
              src="/images/icons/github-footer.svg"
              alt="footer link to Tiaan Jonker's Github site"
              className="footer-icon"
            />
          </a>
          <a
            href="https://www.linkedin.com/in/tiaan-jonker-45283380/"
            target="_blank"
            rel="noreferrer"
          >
            <img
              src="/images/icons/linkdin-footer.svg"
              alt="footer link to Tiaan Jonker's Linkdin site"
              className="footer-icon"
            />
          </a>
        </div>
        <p>Copyright &copy;2022 - Designed and created by Tiaan Jonker </p>
      </div>
    </footer>
  )
}

export default Footer
