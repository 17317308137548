import React from 'react'

const icons = [
  'html',
  'css',
  'js',
  'node',
  'react',
  'redux',
  'jest',
  'github',
  'figma',
  'knex',
]

function EDA() {
  return (
    <section id="eda" className="section">
      <h3 className="section-heading">Dev Academy.</h3>
      <div className="eda-content">
        <div className="eda-paragraphs">
          <p>
            <a
              href="https://devacademy.co.nz/"
              target="_blank"
              className="link"
              rel="noreferrer"
            >
              Dev Academys
            </a>{' '}
            Bootcamp is probabily the most challenging thing I have ever done.
            But looking back at it, and the experience I gained, it was all
            worth it. I had the time of my life.
          </p>
          <p>
            Everything about the programme is hands on and industry relevant.
            Whenever we learned about a new piece of tech or concept we would be
            given a challenge to solve and complete it, always in pairs.
          </p>
          <p>
            But the programme is more than just coding. I learned how to pair
            programme, give and receive feedback, how to run a group project,
            manage conflict and stress. The two parts, coding and human skills
            go hand in hand.
          </p>
          <p>
            The days were long as{' '}
            <a
              href="https://devacademy.co.nz/"
              target="_blank"
              className="link"
              rel="noreferrer"
            >
              Dev Academys
            </a>{' '}
            embrased the philosophy of ‘just in time’ learning. We had to trust
            that we will be given just enough to solve the next upcoming
            challange. I was frequently uncomfortable most of the time because
            of the sheer amount of information we had to process.
          </p>
          <p>
            It was challenging. It was stressful. But 100% worth it and I would
            do it again. I came out loving to code and an overall better human.
          </p>
          <p>
            If you want to read a reflection on my Bootcamp experience then you
            can{' '}
            <a
              href="https://www.linkedin.com/pulse/bootcamp-reflections-dev-academy-tiaan-jonker/?trackingId=ctruaAUjTlugxTeiCY4Dow%3D%3D"
              target="_blank"
              className="link"
              rel="noreferrer"
            >
              view that here.
            </a>
          </p>
        </div>
        <div className="icons-content">
          <p>Tech stack learned & tools used:</p>
          <div className="icons-container">
            {icons.map((iconName) => {
              return (
                <div key={iconName} className="icon-box">
                  <img
                    src={`images/icons/${iconName}-icon.svg`}
                    alt=""
                    className="icon"
                  />
                  <p className="icon-text">{iconName.toUpperCase()}</p>
                </div>
              )
            })}
          </div>
        </div>
      </div>
    </section>
  )
}

export default EDA
