import * as React from 'react'

import MobileDisplay from './MobileDisplay'
import { useState, useEffect } from 'react'
import OtherDisplay from './OtherDisplay'

const getWindowDimensions = () => {
  const { innerWidth: width } = window
  return {
    width,
  }
}

const App = () => {
  const [onMobile, setOnMobile] = useState(false)
  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  )

  useEffect(() => {
    if (windowDimensions.width > 970) {
      setOnMobile(false)
    } else if (windowDimensions.width < 970) {
      setOnMobile(true)
    }

    const windowSize = () => {
      setWindowDimensions(getWindowDimensions())
    }

    window.addEventListener('resize', windowSize)

    return () => {
      window.removeEventListener('resize', windowSize)
    }
  }, [windowDimensions])

  return (
    <div>
      {onMobile ? (
        <div>
          <MobileDisplay />
        </div>
      ) : (
        <div className="container">
          <OtherDisplay />
        </div>
      )}
    </div>
  )
}

export default App
