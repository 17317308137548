import * as React from 'react'
import BlogProj from './BlogProj'
import BushWalkProj from './BushWalkProj'
import CloudProj from './CloudProj'
import ComingSoonProj from './ComingSoonProj'

function Projects() {
  return (
    <section id="projects" className="section section-relative">
      <h3 className="section-heading">Projects.</h3>
      <div className="projects-content">
        <div className="projects-paragraphs">
          <p>
            There is nothing like escaping tutorial hell than completing various
            challanges, katas five group projects and one big final project as a
            team during Bootcamp. That approach has the immediate advantage of
            apply what I have learned while refining my human skills as well.
          </p>
          <p>Here are some selected group projects from Bootcamp:</p>
        </div>
        <div className="project-section">
          <div>
            <BushWalkProj />
          </div>
          <div>
            <CloudProj />
          </div>
          <div>
            <BlogProj />
          </div>
          <div>
            <ComingSoonProj />
          </div>
        </div>
      </div>
    </section>
  )
}

export default Projects
