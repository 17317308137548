import React from 'react'

function MobileDisplay() {
  return (
    <div className="mobile-container">
      <div className="mobile-logo-container">
        <img src="/images/web-logo.svg" alt="" className="mobile-logo" />
      </div>
      <div className="mobile-content">
        <h1>Hello, I'm Tiaan Jonker</h1>
        <h2>See my work on desktop</h2>
        <p>
          I'm a software developer that recently graduated from Dev Academy's 15
          week intensive Bootcamp.
        </p>
        <p>
          I'm looking for a full time position in Auckland. Check out the links
          below and if you like what you see feel free to contact me via Lindkin
          or email.
        </p>
        <p>I love to code, develop, and a quality cup of coffee.</p>
        <div className="mobile-footer">
          <p>Find out more about me here</p>
          <a
            href="https://www.linkedin.com/in/tiaan-jonker-45283380/"
            target="_blank"
            className="mobile-link"
            rel="noreferrer"
          >
            Linkdin
          </a>{' '}
          •{' '}
          <a
            href="mailto:tiaanj06@gmail.com"
            target="_blank"
            rel="noreferrer"
            className="mobile-link"
          >
            Email
          </a>{' '}
          •{' '}
          <a
            href="https://github.com/tiaan-jonker"
            target="_blank"
            className="mobile-link"
            rel="noreferrer"
          >
            Github
          </a>
        </div>
      </div>
    </div>
  )
}

export default MobileDisplay
